<template>
  <div class="station-games-management">
    <ContentBlock class="deployementmanagementblock">
      <template #instructions>
        <h5>{{ $t('Enable Content on your Stations') }}</h5>
        <p
          v-if="gameType === 'cds'"
        >{{ $t('When you enable “One-Click” titles on your stations, it will automatically download and install on your computers when you save this page and your station isn’t actively being used. Once the download is complete the content will be available in the SpringboardVR Launcher.') }}</p>
        <p
          v-else-if="gameType === 'steam' && game.ownership.freeDownload"
        >{{ $t('In order to play this content in the SpringboardVR Launcher, use the toggle to enable the title on your desired station and make sure it is installed on your computer. Use your own license or request a Steam Key if needed. If you want to remove this title from a station, disable it using the toggle and manually uninstall it.') }}</p>
        <p
          v-else
        >{{ $t('In order to launch this title on a station, you’ll need to use your own license, enable the title on your desired station using the toggle, and ensure it has been installed on your computer. If you want to remove this title from a station, disable it using the toggle and manually uninstall it.') }}</p>
      </template>
      <div class="f-body">
      <div class="allstations">
          <UISwitch
            class="allstationswitch"
            :title="$t('Enable on all the stations in your account')"
            :value="allStationsEnabled"
            @click="onAllStationClick"
          >
            <template
              #additional
              v-if="gameType === 'steam' && game.ownership.freeDownload && stationGamesWithoutKeys.length"
            >
              <UIButton
                link
                class="requestkeybtn tl"
                @click="requestGameKeysForAllStations()"
              >{{ $t('Request Steam Keys for all the stations in your account') }}</UIButton>
            </template>
          </UISwitch>
        </div>
        <div class="switchgrid bt b--geyser pt5 mt5">
          <template v-for="station in stations">
            <ContentStationManagementSwitches
              :key="station.id"
              :title="station.title"
              :stationGame="stationGamesByStation[station.id]"
              :gameType="gameType"
              @installStationGame="installStationGame"
              @uninstallStationGame="uninstallStationGame"
              @requestKeyForStationGame="requestKeyForStationGame"
              @enableStationGame="enableStationGame"
              @disableStationGame="disableStationGame"
            />
          </template>
        </div>
      </div>
    </ContentBlock>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import ContentBlock from '@/core/shared/components/ContentBlock';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UIButton from '@/core/shared/components/ui/UIButton';
import ContentStationManagementSwitches from '@/shared/content-station-management/ContentStationManagementSwitches';

@Component({
  components: {
    ContentBlock,
    UISwitch,
    UIButton,
    ContentStationManagementSwitches,
  },
  props: {
    game: { required: true },
    gameType: { required: true },
  },
})
export default class StationGamesManagementContentOnly extends Vue {
  get stations () {
    return this.$store.getters['entities/Station/all']();
  }

  get stationGames () {
    return this.$store.getters[`stationManagement:${this.game.id}/stationGames`];
  }

  get stationGamesByStation () {
    return this.$store.getters[`stationManagement:${this.game.id}/stationGamesByStation`];
  }

  get allStationsEnabled () {
    return this.stations.every(station => this.stationGamesByStation[station.id] && this.stationGamesByStation[station.id].enabled);
  }

  get stationGamesWithoutKeys () {
    return this.stationGames.filter(({ key }) => !key);
  }

  requestGameKeysForAllStations () {
    this.$store.disptch(`stationManagement:${this.game.id}/batchRequestGameKey`, this.stationGames);
  }

  onAllStationClick (e) {
    const val = e.target.checked;
    const nonCDSEnabledMsg = this.$t('Are you sure you want to enable this content for your entire account? Please make sure it is installed on your computers.');
    const CDSEnabledMsg = this.$t('Are you sure you want to enable this content for your entire account? It will be installed and enabled on all stations once you save.');
    if (!val) {
      if (this.gameType !== 'cds') {
        this.$store.dispatch(`stationManagement:${this.game.id}/disableAllStationGamesForAccount`);
      } else {
        e.preventDefault();
        this.$store.commit('setConfirm', {
          show: true,
          message: this.$t('If you would like to keep the content installed on all the computers at your location but disabled on your stations, select “Disable Only”. To uninstall the content, click on “Disable & Uninstall”.'),
          icon: 'error',
          buttons: [
            {
              name: this.$t('Disable Only'),
              type: 'ghost',
              action: () => {
                e.target.checked = false; // should not be required but fixes a bug with safari see https://github.com/vuejs/vue/issues/3523
                this.$store.dispatch(`stationManagement:${this.game.id}/disableAllStationGamesForAccount`);
              },
            },
            {
              name: this.$t('Disable & Uninstall'),
              action: () => {
                e.target.checked = false; // should not be required but fixes a bug with safari see https://github.com/vuejs/vue/issues/3523
                this.$store.dispatch(`stationManagement:${this.game.id}/uninstallAllStationGamesForAccount`);
              },
            },
          ],
        });
      }
    } else {
      e.preventDefault();
      const action = this.gameType === 'cds' ? 'installAllStationGamesForAccount' : 'enableAllStationGamesForAccount';
      this.$store.commit('setConfirm', {
        show: true,
        message: this.gameType === 'cds' ? CDSEnabledMsg : nonCDSEnabledMsg,
        icon: 'check-circle',
        buttons: [
          {
            name: this.$t('Cancel'),
            type: 'cancel',
          },
          {
            name: this.$t('Yes, Enable'),
            action: () => {
              e.target.checked = true; // should not be required but fixes a bug with safari see https://github.com/vuejs/vue/issues/3523
              this.$store.dispatch(`stationManagement:${this.game.id}/${action}`);
            },
          },
        ],
      });
    }
  }

  installStationGame (stationGame) {
    this.$store.dispatch(`stationManagement:${this.game.id}/installStationGame`, stationGame);
  }

  requestKeyForStationGame (stationGame) {
    this.$store.dispatch(`stationManagement:${this.game.id}/requestGameKey`, stationGame);
  }

  uninstallStationGame (stationGame) {
    this.$store.dispatch(`stationManagement:${this.game.id}/uninstallStationGame`, stationGame);
  }

  disableStationGame (stationGame) {
    this.$store.dispatch(`stationManagement:${this.game.id}/disableStationGame`, stationGame);
  }

  enableStationGame (stationGame) {
    this.$store.dispatch(`stationManagement:${this.game.id}/enableStationGame`, stationGame);
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.station-games-management {
  & .switchgrid {
    display: grid;
    grid-template-columns: 1fr;
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
    grid-gap: var(--spacingSm);
  }
  & .requestkeybtn {
    text-align: left;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--colorInfoFlat);
  }
}
</style>
